import { createBrowserRouter } from "react-router-dom";
import Login from "../views/Login";
import Home from "../views/Home";
import Profile from "../views/Profile";
import Register from "../views/Register";
import { Protected } from "../Auth/Protected";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/login",
    element: <Login/>,

  },
  {
    path:"/profile",
    element:<Protected><Profile/></Protected>
  },
  {
    path:"/register",
    element:<Register/>
  }

]);

export default router